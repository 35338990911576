import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Axios from "axios";

const Upload = () => {
  let { id } = useParams();
  const [exist, setExist] = useState(false);
  const [status, setStatus] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [fileStatus, setFileStatus] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  useEffect(() => {
    async function checkID() {
      await Axios.get(`https://api.wctland.com/checkID/${id}`).then((res) => {
        console.log(res.data);
        setExist(res.data.status);
        setStatus(res.data.uploaded);

        if (res.data.hasUploaded) {
          setUploaded(true);
        }
      });
    }
    checkID();
  }, [exist]);

  function Upload(e) {
    e.preventDefault();
    if (file && fileStatus) {
      let data = { file, booking_number: id, type };
      async function UploadFiles() {
        await Axios.post(
          "https://api.wctland.com/uploadslip",
          { data },
          (err, res) => {
            throw console.log(err);
          }
        );
      }
      UploadFiles();
      setUploaded(true);
    }
  }

  function handleFile(e) {
    let fileTypeName = e.target.files[0].type;
    console.log(e.target.files.length);

    if (e.target.files.length > 0) {
      if (
        fileTypeName == "application/pdf" ||
        fileTypeName == "image/jpg" ||
        fileTypeName == "image/jpeg" ||
        fileTypeName == "image/png"
      ) {
        var fileToLoad = e.target.files[0];
        var fileReader = new FileReader();
        var base64File;
        // Reading file content when it's loaded
        fileReader.onload = function (event) {
          base64File = event.target.result;
          // base64File console
          setFile(base64File.split(",")[1]);
          setFileStatus(true);
          setType(e.target.files[0].type);
          console.log("Converted");
        };

        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
      } else {
        setFileStatus(false);
        console.log("Wrong file type");
      }
    }
  }

  return (
    <div>
      {exist ? (
        <div>
          {status ? (
            "Not booked"
          ) : (
            <>
              {uploaded ? (
                <p>
                  File has been uploaded, a confirmation email will be sent to
                  you once approved.
                </p>
              ) : (
                <div>
                  <form onSubmit={Upload}>
                    <input type="file" onChange={handleFile} />{" "}
                    <button>Upload</button>
                  </form>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        "Link invalid"
      )}
    </div>
  );
};

export default Upload;

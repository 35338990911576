import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Agent from "./Agent";
import ClientFill from "./client/ClientFill";
import Tnc from "./Tnc";
import CheckBoxPrivacy from "./CheckBoxPrivacy";
import AddPurchaser from "../utils/AddPurchaser";
import RemovePurchaser from "../utils/RemovePurchaser";

export default function Second({
  data,
  setData,
  type,
  setType,
  code,
  remote,
  id,
  editRef,
  syncWithPartner,
  info,
}) {
  const [isOpen, setIsOpen] = useState(false);

  let history = useHistory();

  const Modal = () => {
    if (isOpen) {
      return setIsOpen(false);
    } else if (!isOpen) {
      return setIsOpen(true);
    }
  };

  const SubmitSecond = (e) => {
    e.preventDefault();
    console.log(data.agent);
    setData({ ...data, status2: "true" });
    history.push("/finalization");
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {type.agent ? (
        <Tabs>
          <TabList>
            <Tab>Purchaser Details</Tab>
            <Tab>Developer Details</Tab>
          </TabList>
          <TabPanel>
            <form onSubmit={(e) => SubmitSecond(e)}>
              <ClientFill
                setType={setType}
                data={data}
                setData={setData}
                Modal={Modal}
                SubmitSecond={SubmitSecond}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                AddPurchaser={AddPurchaser}
                RemovePurchaser={RemovePurchaser}
                code={code}
                remote={remote}
                id={id}
                editRef={editRef}
                syncWithPartner={syncWithPartner}
                info={info}
              />
              <br />

              <hr></hr>
              <div className="privacy">
                {isOpen ? (
                  <Tnc isOpen={isOpen} setIsOpen={setIsOpen} Modal={Modal} />
                ) : null}

                <div className="tnc-button">
                  <label onClick={(e) => Modal(e)} className="tnc-button-label">
                    Terms and Conditions
                  </label>
                </div>

                <label>
                  <input
                    type="checkbox"
                    checked={data.privacy}
                    onChange={() => {
                      CheckBoxPrivacy({ data, setData });
                      syncWithPartner();
                    }}
                  />
                  I have fully read and understood your Privacy Notice in WCT
                  Berhad website in English / Bahasa Malaysia and I/We hereby
                  agree and consent to you and your Group to use my and/or our
                  employee's Personal Data in accordance with the terms and
                  conditions set out in the Privacy Notice.
                </label>
              </div>
              <button className="submit">Proceed</button>
            </form>
          </TabPanel>
          <TabPanel>
            <Agent
              data={data}
              setData={setData}
              remote={remote}
              id={id}
              editRef={editRef}
              syncWithPartner={syncWithPartner}
            />
          </TabPanel>
        </Tabs>
      ) : (
        <form onSubmit={(e) => SubmitSecond(e)}>
          <ClientFill
            data={data}
            setData={setData}
            AddPurchaser={AddPurchaser}
            RemovePurchaser={RemovePurchaser}
            code={code}
            remote={remote}
            id={id}
            editRef={editRef}
            syncWithPartner={syncWithPartner}
            info={info}
          />
          <br />

          <hr></hr>
          <div className="privacy">
            {isOpen ? (
              <Tnc isOpen={isOpen} setIsOpen={setIsOpen} Modal={Modal} />
            ) : null}

            <div className="tnc-button">
              <label onClick={(e) => Modal(e)} className="tnc-button-label">
                Terms and Conditions
              </label>
            </div>

            <label>
              <input
                type="checkbox"
                checked={data.privacy}
                onChange={() => {
                  CheckBoxPrivacy({ data, setData });
                  syncWithPartner();
                }}
              />
              I have fully read and understood your Privacy Notice in WCT Berhad
              website in English / Bahasa Malaysia and I/We hereby agree and
              consent to you and your Group to use my and/or our employee's
              Personal Data in accordance with the terms and conditions set out
              in the Privacy Notice.
            </label>
          </div>
          <button className="submit">Proceed</button>
        </form>
      )}
    </div>
  );
}

import "./App.css";
import WCT from "./assets/wct.png";
import { useState, useEffect, useRef } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import axios from "axios";
import Pusher from "pusher-js";
import ClientLogin from "./comps/client/ClientLogin";
import AgentLogin from "./comps/agent/AgentLogin";
import Second from "./comps/Second";
import Finalization from "./comps/Finalization";
import Finalized from "./comps/Finalized";
import Upload from "./comps/client/Upload";
import io from "socket.io-client";

function App() {
  // For code
  const [code, setCode] = useState(0);
  const [sign, setSign] = useState({
    client:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAArSURBVEhL7c0xAQAwEAOh+jeduuCXwwBvR4qZYqaYKWaKmWKmmClmjuLtA3h+JIXGCn/aAAAAAElFTkSuQmCC",
    agent:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAArSURBVEhL7c0xAQAwEAOh+jeduuCXwwBvR4qZYqaYKWaKmWKmmClmjuLtA3h+JIXGCn/aAAAAAElFTkSuQmCC",
  });
  const remote = useRef(false);
  const id = useRef(`${Date.now()}`);
  const editRef = useRef(null);

  // All Data stored here

  const [sync, setSync] = useState(false);
  const [type, setType] = useState({
    agent: false,
    client: false,
  });
  const [info, setInfo] = useState(null);

  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    office: "",
    idNo: "",
    mailing: "",
    mailing2: "",
    mailing3: "",
    mailing4: "",
    sign: null,
    status: "false",
    status2: "false",
    privacy: false,
    consent: false,
    sync: false,
    agent: {
      parcelNo: "",
      carparkNo: "",
      parcelType: "",
      builtArea: "",
      builtAreaFT: "",
      parcelPrice: "",
      bumiDiscount: "",
      bumiDiscountAmount: "",
      spaPrice: "",
      bookingFee: "",
      paymentMode: "",
      chequeNo: "",
      cardCode: "",
      receiptNo: "",
      cashPurchase: "",
      endFinance: "",
      solicitor: "",
      sign: null,
      status: "false",
      agency: "",
      name: "",
      contact: "",
      email: "",
    },
    purchasersNum: 1,
    purchaser1: {
      name: "",
      idNo: "",
      gender: "",
      race: "",
      contact: "",
      sign: "",
    },
    purchaser2: {
      name: "",
      idNo: "",
      gender: "",
      race: "",
      contact: "",
      sign: "",
    },
    purchaser3: {
      name: "",
      idNo: "",
      gender: "",
      race: "",
      contact: "",
      sign: "",
    },
    purchaser4: {
      name: "",
      idNo: "",
      gender: "",
      race: "",
      contact: "",
      sign: "",
    },
    project: "The Maple Residences",
  });

  let history = useHistory();

  const io = require("socket.io-client");
  const socket = io("https://booking-sync.wctland.com", {
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "abcd",
    },
  });

  useEffect(() => {
    socket.on(`${code}`, ({ partnerId, data, infos }) => {
      if (id.current !== partnerId) {
        remote.current = true;
        if (info == null && infos) {
          setInfo(infos);
        }
        setData(data);
        remote.current = false;
      }
    });
  }, [code]);

  useEffect(() => {
    // console.log(info, data);
  }, [data]);

  async function syncWithPartner() {
    // console.log(info);
    if (!remote.length) {
      socket.emit("agent-connect", {
        partnerId: id.current,
        data,
        code,
        infos: info,
      });
    }
  }

  return (
    <div className="App">
      <p className="ver-number">Ver. 1.2.0-beta</p>

      {sync ? null : (
        <div className="topContainer">
          <button
            onClick={(e) => {
              e.preventDefault();
              history.push("/agent-login");
            }}
            type="button">
            Agent Login
          </button>
        </div>
      )}
      <div>
        <img
          alt="logo"
          onClick={(e) => {
            setData({ ...data, status: "false" });
            history.push("/");
          }}
          src={WCT}
          className="logo"
        />
      </div>
      <p style={{ color: "#000000" }}>Booking System</p>

      <Switch>
        <Route exact path="/">
          <ClientLogin
            setSync={setSync}
            type={type}
            setType={setType}
            code={code}
            setCode={setCode}
            data={data}
            setData={setData}
            remote={remote}
            id={id}
            editRef={editRef}
            info={info}
            setInfo={setInfo}
          />
        </Route>

        <Route path="/agent-login">
          <AgentLogin
            setInfo={setInfo}
            info={info}
            type={type}
            setType={setType}
            code={code}
            data={data}
            setCode={setCode}
            setData={setData}
            setSync={setSync}
            remote={remote}
            id={id}
            editRef={editRef}
          />
        </Route>

        <Route path="/form">
          <Second
            code={code}
            type={type}
            setType={setType}
            data={data}
            setData={setData}
            remote={remote}
            id={id}
            editRef={editRef}
            syncWithPartner={syncWithPartner}
            info={info}
          />
        </Route>

        <Route path="/finalization">
          <Finalization
            setSign={setSign}
            info={info}
            sign={sign}
            data={data}
            setData={setData}
            remote={remote}
            id={id}
            editRef={editRef}
            syncWithPartner={syncWithPartner}
            code={code}
            type={type}
          />
        </Route>

        <Route path="/finalized">
          <Finalized type={type} data={data} sign={sign} info={info} />
        </Route>

        <Route path="/upload/:id" children={<Upload />} />
      </Switch>
    </div>
  );
}

export default App;

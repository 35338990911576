export default function Tnc({isOpen, setIsOpen, Modal}){
    return(
        <div className="modal-bg"><div className="modal-container">
            <div className="modal-x"><label onClick={e => Modal(e)}>✖</label></div>
            <div>
                <h3 style={{ color: "#C39956" }}>Terms And Conditions Of Offer To Purchase</h3>

                <ol className="modal-ol">
                    <li><p>My/Our offer to purchase the Property shall only be deemed accepted upon clearance of the booking fee paid by me/us which sum is as stipulated in Section C of the Offer to Purchase (“Booking Fee”) and issuance of the Developer’s official receipt for the same. No other act(s) or conduct(s) shall constitute acceptance. Subject to the Developer’s acceptance of my/our offer to purchase, the Booking Fee so paid shall be deemed as part payment towards the purchase price of the Property ("Purchase Price").</p></li>
                    <li><p>In any of the following events, the Developer shall have the absolute right and be entitled to forthwith rescind the sale and to deal with the Property as the Developer may deem fit in its absolute discretion without further reference to me/us whereupon I/we shall have no claims whatsoever against the Developer:-</p>
                    <ol className="modal-ol2">
                        <li>I/We fail to execute the sale and purchase agreement and all relevant documents (“SPA Documents”) within twenty one (21) days from the date of my/our offer herein;</li>
                        <li>I/We fail to pay in full the first ten per centum (10%) of the Purchase Price within twenty one (21) days from the date of my/our offer herein or within any extended time granted by the Developer to me/us in writing;</li>
                        <li>If any information given herein by me/us is found to be untrue or inaccurate;</li>
                        <li>Unless I/we notify the Developer in writing that I/we am/are a cash purchaser(s), I/we fail to secure a facility/loan for the purchase of the Property within twenty one (21) days from the date of my/our offer herein; and /or</li>
                        <li>I/We commit an act of bankruptcy or enter into any composition or arrangement with my/our creditors.</li>

                    </ol>
                        
                    </li>
                    <li><p>Should I/we withdraw my/our offer to purchase herein or the Developer exercises its right to rescind the sale pursuant to paragraph 2 above, all sum received by the Developer herein (less administrative charges of Ringgit Malaysia Five Hundred (RM500.00) and where payment of the Booking Fee is paid via credit card, an additional two per centum (2%) of the Booking Fee being merchant handling fee) to me/us free of interest.</p></li>
                    <li><p>I/We shall not attempt to sell, transfer, assign or howsoever dispose of the Property to any person(s) prior to the execution of the SPA Documents without the prior written consent of the Developer, which consent the Developer is not obliged to give and the decision of the Developer is final and no appeal will be entertained.</p></li>
                    <li><p>I/We have inspected the plans and specifications of the Property and the Project and hereby confirm that I/we am/are satisfied with the plans, specifications, and locations of the Property. Notwithstanding the foregoing, I/We irrevocably give my/our consent to the Developer to make any deviation(s) from or alteration(s) of the said plans and/or specifications as may be required by the relevant authorities or if the Developer’s architects and/or engineers shall consider necessary or expedient.</p></li>
                    <li><p>No oral communication or representation from the Developer or its agents/staff/employees shall form part of the sale of the Property.</p></li>
                    <li><p>(If Bumiputera discount is applicable) In the event I/we have represented that I/we are purchasing as Bumiputera, the Developer reserves the right to withdraw the Bumiputera discount offered to me/us in reliance of my/our said representation if the required documents furnished by me/us to support my/our representation as Bumiputera are inadequate or I/we could not prove my/our Bumiputera status whereupon the non-Bumiputera purchase price shall apply for the sale of the Property and I/we shall within seven (7) days upon notification by the Developer, pay the difference between the first ten per centum (10%) of the Bumiputera purchase price and the first ten per centum (10%) of the non-Bumiputera purchase price to the Developer, failing which the Developer shall be entitled to rescind the sale of the Property to me/us and I/we shall have no claim whatsoever against the Developer.</p></li>
                    <li><p>Any penalty on the stamp duty payable on the SPA Documents shall be borne by me/us if the SPA Documents are not signed and stamped within one (1) month from the date of payment of the Booking Fee by me/us.</p></li>

                </ol>
            
            </div>
            </div>
            </div>
    )
} 

import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import download from "downloadjs";
import OTPFill from "../assets/pdpa-fillable.pdf";
import blank from "../assets/blank-sign.jpg";
import date from "date-and-time";

const FillForm = async ({ data, sign }) => {
  // Get date
  const now = new Date();
  const dateNow = date.format(now, "DD/MM/YYYY");

  // Fetch blank Fillable PDF
  const formUrl = OTPFill;
  const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

  // Load a PDF with form fields
  const pdfDoc = await PDFDocument.load(formPdfBytes);

  //Fetch Sign example
  const signUrl = sign.client;
  const signImageBytes = await fetch(signUrl).then((res) => res.arrayBuffer());

  // Embed the sign
  const signImage = await pdfDoc.embedPng(signImageBytes);

  // Get the form containting all the fields
  const form = pdfDoc.getForm();

  // Field

  //Description of Property FIELD
  const nameField = form.getTextField("name");
  const idField = form.getTextField("id");
  const dateField = form.getTextField("date");
  const signField = form.getButton("sign");

  signField.setImage(signImage);

  nameField.setText(data.name);
  idField.setText(data.idNo);
  dateField.setText(dateNow);

  nameField.enableReadOnly();
  idField.enableReadOnly();
  dateField.enableReadOnly();

  // Serialize the PDFDocument to bytes(a Uint8Array)
  const pdfBytes = await pdfDoc.save();

  download(pdfBytes, `${data.email}.pdf`, "application/pdf");
};

export default FillForm;

import { useState, useRef } from "react";
import io from "socket.io-client";
import { useHistory } from "react-router-dom";

const ClientLogin = ({
  data,
  setData,
  code,
  setCode,
  type,
  setType,
  setSync,
  remote,
  id,
  editRef,
  info,
  setInfo,
}) => {
  const [locCode, setLocCode] = useState(0);
  const io = require("socket.io-client");
  const socket = io("https://booking-sync.wctland.com", {
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "abcd",
    },
  });
  let history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();
    setCode(locCode);
    setSync(true);
    setData({ ...data, sync: true });
    setType({ agent: false, client: true });

    if (!remote.length) {
      socket.emit("agent-connect", {
        partnerId: id.current,
        data,
        code: locCode,
      });

      history.push("/form");
    }
  };

  return (
    <>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="inputContainer">
          <label className="inputTitle">
            <p>Name</p>
          </label>
          <label className="inputSeparator">
            <p>:</p>
          </label>
          <input
            ref={editRef}
            type="text"
            value={data.name}
            onBlur={(e) => {
              setData({ ...data, name: e.target.value });
              if (!remote.length) {
                socket.emit("agent-connect", {
                  partnerId: id.current,
                  data,
                  code,
                });
              }
            }}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
              if (!remote.length) {
                socket.emit("agent-connect", {
                  partnerId: id.current,
                  data,
                  code,
                });
              }
            }}
          />
        </div>

        <div className="inputContainer">
          <label className="inputTitle">
            <p>Email Address</p>
          </label>
          <label className="inputSeparator">
            <p>:</p>
          </label>
          <input
            ref={editRef}
            type="email"
            value={data.email}
            onBlur={(e) => {
              setData({ ...data, email: e.target.value });
              if (!remote.length) {
                socket.emit("agent-connect", {
                  partnerId: id.current,
                  data,
                  code,
                });
              }
            }}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
              if (!remote.length) {
                socket.emit("agent-connect", {
                  partnerId: id.current,
                  data,
                  code,
                });
              }
            }}
          />
        </div>

        <div className="inputContainer">
          <label className="inputTitle">
            <p>Code</p>
          </label>
          <label className="inputSeparator">
            <p>:</p>
          </label>
          <input
            type="number"
            value={locCode}
            onChange={(e) => setLocCode(e.target.value)}
          />
        </div>

        <div style={{ padding: "50px", color: "#000000" }}>
          It is recommended to proceed with an agent.
        </div>
        <button className="submit">Proceed</button>
      </form>
    </>
  );
};

export default ClientLogin;

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import Pusher from "pusher-js";
import io from "socket.io-client";

export default function AgentLogin({
  setSync,
  type,
  setType,
  data,
  setData,
  setCode,
  code,
  info,
  setInfo,
  remote,
  id,
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [correct, setCorrect] = useState(false);
  let history = useHistory();

  const socket = io();

  const onSubmit = (e) => {
    e.preventDefault();
    async function Login() {
      await Axios.post("https://api.wctland.com/login", { email, password })
        .catch((error) => {
          throw console.log(error);
        })
        .then((res) => {
          if (!res.data.error) {
            setInfo(res.data.data);
            setCorrect(true);
            setType({ agent: true, client: false });
          } else setMessage("User or password does not match");
        });
    }
    Login();
  };

  // async function Post(infos) {
  //   console.log(infos)
  //   var pusher = new Pusher('84f6f90013efbe8c6b01', {
  //     cluster: 'ap1',
  //     encrypted: true
  //   });
  //   var channel = pusher.subscribe('my-channel');
  //   await Axios.post('https://api.wctland.com', {data, code, infos})
  //   .catch((error) => {
  //     throw console.log(error)
  //   }).then((res) => {
  //     channel.bind(`${code}`, function(data) {

  //     });
  //   })

  // }

  useEffect(() => {
    function genCode() {
      let val = Math.floor(1000 + Math.random() * 9000);

      if (code === 0) {
        setCode(val);
      }
    }
    genCode();
  }, []);

  useEffect(() => {
    if (data && data.sync) {
      setSync(true);
      setType({ agent: true, client: false });
      console.log("synced");
      history.push("/form");
    }
  }, [data]);

  return correct ? (
    <>
      <h3>Logged In</h3>
      Inform your client of this code to sync up your forms. <br />
      <p>
        <b>{code}</b>
      </p>{" "}
      Please wait while your client fill in the code, you'll automatically go to
      the form once you've synced up.
    </>
  ) : (
    <>
      <form onSubmit={(e) => onSubmit(e)}>
        <h3>Agent Login</h3>

        <div className="inputContainer">
          <label className="inputTitle">
            <p>Email</p>
          </label>
          <label className="inputSeparator">
            <p>:</p>
          </label>
          <input
            className="numberLogin"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="inputContainer">
          <label className="inputTitle">
            <p>Password</p>
          </label>
          <label className="inputSeparator">
            <p>:</p>
          </label>
          <input
            className="numberLogin"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <p>{message}</p>

        <button className="submit">Proceed</button>
      </form>
    </>
  );
}

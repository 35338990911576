import { useHistory } from "react-router-dom";
import CheckBox from "./CheckBox";
import Signature from "./Signature";
import { useState } from "react";
import Consent from "./Consent";
import ClientFill from "./client/ClientFill";
import AddPurchaser from "../utils/AddPurchaser";
import RemovePurchaser from "../utils/RemovePurchaser";
import Agent from "./Agent";

export default function Finalization({
  data,
  setData,
  sign,
  setSign,
  info,
  remote,
  id,
  editRef,
  syncWithPartner,
  code,
  type,
}) {
  const [warning, setWarning] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  let history = useHistory();

  const Modal = (e) => {
    if (isOpen) {
      setIsOpen(false);
    } else if (!isOpen) {
      setIsOpen(true);
    }
  };

  const Finalize = (e) => {
    e.preventDefault();
    // setData({...data, status2: 'true'})
    if (sign.client !== "" && sign.agent !== "" && data.consent) {
      history.push("/finalized");
    } else {
      setWarning(
        "Please confirm the consent checkbox and sign before continuing"
      );
    }
  };

  return (
    <form onSubmit={Finalize}>
      <hr></hr>
      <p style={{ color: "#C39956" }}>Finalization</p>

      <Agent
        data={data}
        setData={setData}
        remote={remote}
        id={id}
        editRef={editRef}
        syncWithPartner={syncWithPartner}
      />

      <hr></hr>

      {/* CONTACT DETAILS */}

      <ClientFill
        data={data}
        setData={setData}
        AddPurchaser={AddPurchaser}
        RemovePurchaser={RemovePurchaser}
        code={code}
        remote={remote}
        id={id}
        editRef={editRef}
        syncWithPartner={syncWithPartner}
      />

      <hr></hr>

      <h3 style={{ color: "#C39956" }}>
        Consent to Process Personal Data And Personal Data Protection Notice
      </h3>

      <div className="tnc-button">
        <label onClick={(e) => Modal(e)} className="tnc-button-label">
          Read Consent
        </label>
      </div>

      <div className="pdpa">
        {isOpen ? (
          <Consent isOpen={isOpen} setIsOpen={setIsOpen} Modal={Modal} />
        ) : null}

        <h3 style={{ color: "#C39956" }}>Confirmation and Consent</h3>

        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={data.consent}
              onChange={() => {
                CheckBox({ data, setData });
                syncWithPartner();
              }}
            />
            I, {data.name}, {data.idNo} (NRIC No.) confirm I have read the above
            Notice and hereby give my consent to WCT Holdings Berhad and its
            related companies (“WCT Group”) to process my Data in accordance
            with the above.
          </label>
        </div>
      </div>

      <div>
        <Signature
          info={info}
          setSign={setSign}
          sign={sign}
          data={data}
          setData={setData}
          syncWithPartner={syncWithPartner}
          id={id}
          editRef={editRef}
          syncWithPartner={syncWithPartner}
          code={code}
          remote={remote}
          type={type}
        />
      </div>

      <hr></hr>
      <p style={{ color: "red" }}>{warning}</p>
      <button className="submit">Finalize</button>
    </form>
  );
}

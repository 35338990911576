export default function Agent({ data, setData, syncWithPartner }) {
  return (
    <form>
      <h3 style={{ color: "#C39956" }}>Description of Property</h3>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>Project</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <option style={{ display: "none" }}> Pick Project </option>
        <select
          value={data.project}
          onChange={(e) => {
            setData({ ...data, project: e.target.value });
            syncWithPartner();
          }}>
          <option value="The Maple Residences">The Maple Residences</option>
          <option value="Waltz Residences">Waltz Residences</option>
          <option value="Paradigm Residence">Paradigm Residence</option>
          <option value="Sapphire Paradigm Residence">
            Sapphire Paradigm Residence
          </option>
          <option value="Medini Signature">Medini Signature</option>
          <option value="Impiria Residensi">Impiria Residensi</option>
          <option value="Renai Jelutong Residences">
            Renai Jelutong Residences
          </option>
          <option value="Azure Residences">Azure Residences</option>
        </select>
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>Parcel No.</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          type="text"
          value={data.agent.parcelNo}
          onChange={(e) => {
            setData({
              ...data,
              agent: { ...data.agent, parcelNo: e.target.value },
            });
            syncWithPartner();
          }}
        />
      </div>

      {/* <div className="inputContainer">
        <label className="inputTitle">
          <p>Parcel Type</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          type="text"
          value={data.agent.parcelType}
          onChange={(e) =>
            setData({
              ...data,
              agent: { ...data.agent, parcelType: e.target.value },
            })
          }
        />
      </div> */}

      {/* <div className="inputContainer">
        <label className="inputTitle">
          <p>Carpark No.</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          type="text"
          value={data.agent.carparkNo}
          onChange={(e) =>
            setData({
              ...data,
              agent: { ...data.agent, carparkNo: e.target.value },
            })
          }
        />
      </div> */}

      {/* <div className="inputContainer">
        <label className="inputTitle">
          <p>Built-Up Area</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <div className="BuiltContainer">
          <label className="RM">SQ FT</label>
          <input
            type="number"
            value={data.agent.builtArea}
            onChange={(e) =>
              setData({
                ...data,
                agent: { ...data.agent, builtArea: e.target.value },
              })
            }
          />
        </div>
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p></p>
        </label>
        <label className="inputSeparator">
          <p></p>
        </label>
        <div className="BuiltContainer">
          <label className="RM">SQ M</label>
          <input
            type="number"
            value={data.agent.builtAreaFT}
            onChange={(e) =>
              setData({
                ...data,
                agent: { ...data.agent, builtAreaFT: e.target.value },
              })
            }
          />
        </div>
      </div> */}

      {/* <hr></hr>

      <h3 style={{ color: "#C39956" }}>Payment Details</h3>
      <div className="inputContainer">
        <label className="inputTitle">
          <p>Parcel Price</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <div className="RMContainer">
          <label className="RM">RM</label>
          <input
            className="inputPrice"
            type="number"
            value={data.agent.parcelPrice}
            onChange={(e) =>
              setData({
                ...data,
                agent: { ...data.agent, parcelPrice: e.target.value },
              })
            }
          />
        </div>
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>Bumi Discount</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <select
          value={data.agent.bumiDiscount}
          onChange={(e) =>
            setData({
              ...data,
              agent: { ...data.agent, bumiDiscount: e.target.value },
            })
          }>
          <option style={{ display: "none" }}> Select </option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>

      {data.agent.bumiDiscount === "yes" ? (
        <div className="inputContainer">
          <label className="inputTitle">
            <p>Bumi Discount Amount</p>
          </label>
          <label className="inputSeparator">
            <p>:</p>
          </label>
          <div className="RMContainer">
            <label className="RM">RM</label>
            <input
              className="inputPrice"
              type="number"
              value={data.agent.bumiDiscountAmount}
              onChange={(e) =>
                setData({
                  ...data,
                  agent: { ...data.agent, bumiDiscountAmount: e.target.value },
                })
              }
            />
          </div>
        </div>
      ) : null}

      <div className="inputContainer">
        <label className="inputTitle">
          <p>SPA Price</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <div className="RMContainer">
          <label className="RM">RM</label>
          <input
            className="inputPrice"
            type="number"
            value={data.agent.spaPrice}
            onChange={(e) =>
              setData({
                ...data,
                agent: { ...data.agent, spaPrice: e.target.value },
              })
            }
          />
        </div>
      </div> */}

      {/* <h3 style={{ color: "#C39956" }}>Payment Details</h3>
        <div className="inputContainer">
          <label className="inputTitle"><p>Parcel Price</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <div className="RMContainer">
          <label className="RM">RM</label>
          <input className="inputPrice" type="number" value={data.agent.parcelPrice} onChange={e => setData({...data, agent: {...data.agent, parcelPrice: e.target.value}})} />
          </div>
        </div>
        
        <div className="inputContainer">
          <label className="inputTitle"><p>Bumi Discount</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <select value={data.agent.bumiDiscount} onChange={e => setData({...data, agent: {...data.agent, bumiDiscount: e.target.value}})}>
          <option style={{display: 'none'}}> Select  </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        {data.agent.bumiDiscount === 'yes' ? (<div className="inputContainer">
          <label className="inputTitle"><p>Bumi Discount Amount</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <div className="RMContainer">
          <label className="RM">RM</label>
          <input className="inputPrice" type="number" value={data.agent.bumiDiscountAmount} onChange={e => setData({...data, agent: {...data.agent, bumiDiscountAmount: e.target.value}})} />
          </div>
        </div>) : null}
            
        <div className="inputContainer">
          <label className="inputTitle"><p>SPA Price</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <div className="RMContainer">
          <label className="RM">RM</label>
          <input className="inputPrice" type="number" value={data.agent.spaPrice} onChange={e => setData({...data, agent: {...data.agent, spaPrice: e.target.value}})}/>
          </div>
        </div>
            
        <div className="inputContainer">
          <label className="inputTitle"><p>Booking Fee</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <div className="RMContainer">
          <label className="RM">RM</label>
          <input className="inputPrice" type="number" value={data.agent.bookingFee} onChange={e => setData({...data, agent: {...data.agent, bookingFee: e.target.value}})}/>
          </div>
        </div>
            
        <div className="inputContainer">
          <label className="inputTitle"><p>Payment Mode</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <select value={data.agent.paymentMode} onChange={e => setData({...data, agent: {...data.agent, paymentMode: e.target.value}})}>
          <option style={{display: 'none'}}> Select Payment Mode </option>
            <option value="cash">Cash</option>
            <option value="cheque">Cheque</option>
            <option value="card">Card</option>
          </select>        
          </div>

        <div className="inputContainer">
          <label className="inputTitle"><p>Cheque No.</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <input type="text" value={data.agent.chequeNo} onChange={e => setData({...data, agent: {...data.agent, chequeNo: e.target.value}})}/>
        </div>

        <div className="inputContainer">
          <label className="inputTitle"><p>Card Approval Code</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <input type="text" value={data.agent.cardNo} onChange={e => setData({...data, agent: {...data.agent, cardNo: e.target.value}})}/>
        </div>

        <div className="inputContainer">
          <label className="inputTitle"><p>WCT Receipt No.</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <input type="text" value={data.agent.receiptNo} onChange={e => setData({...data, agent: {...data.agent, receiptNo: e.target.value}})}/>
        </div>

        <div className="inputContainer">
          <label className="inputTitle"><p>Cash Purchase</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <select value={data.agent.cashPurchase} onChange={e => setData({...data, agent: {...data.agent, cashPurchase: e.target.value}})}>
          <option style={{display: 'none'}}> Cash Purchase? </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          </div>

        <div className="inputContainer">
          <label className="inputTitle"><p>End Financing</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <input type="text" placeholder="(Bank's Name)" value={data.agent.endFinance} onChange={e => setData({...data, agent: {...data.agent, endFinance: e.target.value}})}/>
        </div>

        <div className="inputContainer">
          <label className="inputTitle"><p>Solicitor</p></label>
          <label className="inputSeparator"><p>:</p></label>
          <input type="text" value={data.agent.solicitor} onChange={e => setData({...data, agent: {...data.agent, solicitor: e.target.value}})}/>
        </div> */}
    </form>
  );
}

import FillForm from "./FillForm";
import FillForm2 from "./FillForm2";
import FillFormPDPA2 from "./FillFormPDPA2";
import axios from "axios";
import FillFormPDPA from "./FillFormPDPA";
import { useEffect } from "react";

const Finalized = ({ data, sign, info, type }) => {
  useEffect(() => {
    const BookNode = async (e) => {
      const otp = await FillForm2({ data, sign, info });
      const pdpa = await FillFormPDPA2({ data, sign });
      await axios
        .post("https://api.wctland.com/book", { data, otp, pdpa, info })
        .catch((error) => {
          throw console.log(error);
        });
    };

    if (type.client) {
      BookNode();
    } else return null;
  }, []);

  return (
    <div className="completed">
      {/* <p>Thank you, a copy of the booking forms has been sent to your email {data.email}. You can also download it directly here.</p> */}
      <p>
        Thank you, here is a copy of your booking forms. The copies has also
        been sent to your email.
      </p>

      <button
        className="submit"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          FillForm({ data, sign, info });
        }}>
        Download OTP
      </button>
      <br />
      <button
        className="submit"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          FillFormPDPA({ data, sign });
        }}>
        Download PDPA
      </button>
      <div className="space"></div>
    </div>
  );
};

export default Finalized;

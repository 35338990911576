import SignaturePad from "react-signature-canvas";
import { useState, useRef, useEffect, info } from "react";
import io from "socket.io-client";

export default function Signature({
  setData,
  data,
  sign,
  setSign,
  info,
  id,
  editRef,
  code,
  remote,
  type,
}) {
  const sigRef = useRef();
  const sigRefE = useRef();
  const agentRef = useRef();
  const agentRefE = useRef();
  const sigButton = useRef();
  const agentSig = useRef();
  const [signMode, setSignMode] = useState(true);
  const [signModeAgent, setSignModeAgent] = useState(true);

  const io = require("socket.io-client");
  const socket = io("https://booking-sync.wctland.com", {
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "abcd",
    },
  });

  const fileRef = useRef();
  let blank =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAArSURBVEhL7c0xAQAwEAOh+jeduuCXwwBvR4qZYqaYKWaKmWKmmClmjuLtA3h+JIXGCn/aAAAAAElFTkSuQmCC";

  const Clear = () => {
    setSign({
      ...sign,
      client: blank,
    });
  };

  const ClearAgent = () => {
    setSign({
      ...sign,
      agent: blank,
    });
  };

  useEffect(() => {
    socket.on(`${code}-sig`, ({ partnerId, agent, client }) => {
      if (id.current !== partnerId) {
        remote.current = true;

        if (id.current !== partnerId) {
          setSign({ ...sign, client, agent });
        }

        remote.current = false;
      }
    });
  }, []);

  const SubmitSignature = () => {
    if (sigRef.current !== null) {
      setSign({
        ...sign,
        client: sigRef.current.getTrimmedCanvas().toDataURL("image/png"),
      });
      if (!remote.length) {
        return socket.emit("sig-connect", {
          client: sigRef.current.getTrimmedCanvas().toDataURL("image/png"),
          agent: sign.client,
          partnerId: id.current,
          code,
        });
      }
    } else if (sigRefE.current !== null) {
      let inputText = document.getElementById("inputTextClient").value;
      let ctx = sigRefE.current.getContext("2d");
      ctx.font = "3rem Rock Salt, cursive";
      ctx.textAlign = "center";
      ctx.fillText(inputText, 150, 120);
      console.log(ctx.font);

      if (!remote.length) {
        return socket.emit("sig-connect", {
          client: ctx.canvas.toDataURL(),
          agent: sign.agent,
          partnerId: id.current,
          code,
        });
      }
    } else return null;
  };

  const SubmitAgentSignature = () => {
    if (agentRef.current !== null) {
      setSign({
        ...sign,
        agent: agentRef.current.getTrimmedCanvas().toDataURL("image/png"),
      });
      if (!remote.length) {
        return socket.emit("sig-connect", {
          agent: agentRef.current.getTrimmedCanvas().toDataURL("image/png"),
          client: sign.client,
          partnerId: id.current,
          code,
        });
      }
    } else if (agentRefE.current !== null) {
      let inputText = document.getElementById("inputTextAgent").value;
      let ctx = agentRefE.current.getContext("2d");
      ctx.font = "3rem Rock Salt, cursive";
      ctx.textAlign = "center";
      ctx.fillText(inputText, 150, 120);
      console.log(ctx.font);

      if (!remote.length) {
        return socket.emit("sig-connect", {
          agent: ctx.canvas.toDataURL(),
          client: sign.client,
          partnerId: id.current,
          code,
        });
      }
    } else return null;
  };

  useEffect(() => {
    if (signMode == "true") {
      setSignMode(true);
    }

    if (signMode == "false") {
      setSignMode(false);
    }
  }, [signMode]);

  useEffect(() => {
    if (signModeAgent == "true") {
      setSignModeAgent(true);
    }

    if (signModeAgent == "false") {
      setSignModeAgent(false);
    }
  }, [signModeAgent]);

  const changeHandler = (e) => {
    setSignMode(e.target.value);
    Clear();
  };

  const changeHandlerAgent = (e) => {
    setSignModeAgent(e.target.value);
    ClearAgent();
  };

  let signs =
    sign.client !== blank ? (
      <img className="signed" src={sign.client} />
    ) : (
      <SignaturePad canvasProps={{ className: "sign" }} ref={sigRef} />
    );

  let eSigns =
    sign.client !== blank ? (
      <img id="imageText" className="signed" src={sign.client} />
    ) : (
      <div className="sign">
        <canvas className="canvasType" ref={sigRefE}></canvas>
        <input
          id="inputText"
          placeholder="Type here"
          spellCheck={false}
          className="type"
          type="text"
        />
      </div>
    );

  let signsAgent =
    sign.agent !== blank ? (
      <img className="signed" src={sign.agent} />
    ) : (
      <SignaturePad canvasProps={{ className: "sign" }} ref={agentRef} />
    );

  let eSignsAgent =
    sign.agent !== blank ? (
      <img id="imageText" className="signed" src={sign.agent} />
    ) : (
      <div className="sign">
        <canvas className="canvasType" ref={agentRefE}></canvas>
        <input
          id="inputTextAgent"
          placeholder="Type here"
          spellCheck={false}
          className="type"
          type="text"
        />
      </div>
    );

  return (
    <div className="signaturePad">
      <div>
        {type.client ? (
          <>{signMode ? signs : eSigns}</>
        ) : (
          <>
            <img id="imageText" className="signed" src={sign.client} />
          </>
        )}
      </div>
      {type.client ? (
        <>
          <button type="button" onClick={Clear}>
            Clear
          </button>
          {sign.client !== blank ? null : (
            <button type="button" ref={sigButton} onClick={SubmitSignature}>
              Submit
            </button>
          )}
          <select value={signMode} onChange={changeHandler}>
            <option value={true}>Draw</option>
            <option value={false}>Type</option>
          </select>
        </>
      ) : null}

      <p>Contact Person E-Signature</p>
      <div className="info-box">
        <p>
          Contact Name: <b>{data.name}</b>
        </p>
        <p>
          Contact NRIC/Passport: <b>{data.idNo}</b>
        </p>
        <p>
          Contact: <b>{data.mobile}</b>
        </p>
      </div>
      <div>
        {type.agent ? (
          <>{signModeAgent ? signsAgent : eSignsAgent}</>
        ) : (
          <>
            <img id="imageText" className="signed" src={sign.agent} />
          </>
        )}
      </div>
      {type.agent ? (
        <>
          <button type="button" onClick={ClearAgent}>
            Clear
          </button>
          {sign.agent !== blank ? null : (
            <button type="button" ref={agentSig} onClick={SubmitAgentSignature}>
              Submit
            </button>
          )}

          <select value={signModeAgent} onChange={changeHandlerAgent}>
            <option value={true}>Draw</option>
            <option value={false}>Type</option>
          </select>
        </>
      ) : null}

      <p>Agent Signature</p>
      <div className="info-box">
        <p>
          Agent Name: <b>{info.name}</b>
        </p>
        <p>
          Agency: <b>{info.agency}</b>
        </p>
        <p>
          Contact: <b>{info.contact}</b>
        </p>
        <p>
          Email: <b>{info.email}</b>
        </p>
      </div>
    </div>
  );
}

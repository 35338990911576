import Tnc from "../Tnc";
import CheckBoxPrivacy from "../CheckBoxPrivacy";
import io from "socket.io-client";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ClientFill({
  data,
  setData,
  AddPurchaser,
  RemovePurchaser,
  code,
  id,
  remote,
  editRef,
  syncWithPartner,
  info,
}) {
  const io = require("socket.io-client");
  const socket = io("https://booking-sync.wctland.com", {
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "abcd",
    },
  });

  useEffect(() => {
    setData({ ...data, sync: true });

    if (!remote.length) {
      socket.emit("agent-connect", {
        partnerId: id.current,
        data,
        code,
      });
    }
  }, []);

  return (
    <>
      <h3 style={{ color: "#C39956" }}>Contact Details</h3>
      <div className="inputContainer">
        <label className="inputTitle" onClick={(e) => console.log(data.name)}>
          <p>Name</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          type="text"
          value={data.name}
          onChange={(e) => {
            setData({ ...data, name: e.target.value });
            syncWithPartner();
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>Email Address</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          type="email"
          value={data.email}
          onChange={(e) => {
            setData({ ...data, email: e.target.value });
            syncWithPartner();
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>Mobile No.</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          placeholder="012-345 6789"
          type="text"
          value={data.mobile}
          onChange={(e) => {
            setData({ ...data, mobile: e.target.value });
            syncWithPartner();
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>Office Tel No.</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          placeholder="01-2345 6789"
          type="text"
          value={data.office}
          onChange={(e) => {
            setData({ ...data, office: e.target.value });
            syncWithPartner();
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>NRIC / Co. No. / Passport No.</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          type="text"
          value={data.idNo}
          onChange={(e) => {
            setData({ ...data, idNo: e.target.value });
            syncWithPartner();
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>Mailing Address</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          placeholder="Address Line 1"
          value={data.mailing}
          onChange={(e) => {
            setData({ ...data, mailing: e.target.value });
            syncWithPartner();
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p></p>
        </label>
        <label className="inputSeparator">
          <p></p>
        </label>
        <input
          placeholder="Address Line 2"
          value={data.mailing2}
          onChange={(e) => {
            setData({ ...data, mailing2: e.target.value });
            syncWithPartner();
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p></p>
        </label>
        <label className="inputSeparator">
          <p></p>
        </label>
        <input
          placeholder="Address Line 3"
          value={data.mailing3}
          onChange={(e) => {
            setData({ ...data, mailing3: e.target.value });
            syncWithPartner();
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p></p>
        </label>
        <label className="inputSeparator">
          <p></p>
        </label>
        <input
          placeholder="Address Line 4"
          value={data.mailing4}
          onChange={(e) => {
            setData({ ...data, mailing4: e.target.value });
            syncWithPartner();
          }}
        />
      </div>

      <hr></hr>

      {/* PURCHASER'S DETAILS */}

      <h3 style={{ color: "#C39956" }}>Purchaser's Details</h3>
      <div className="inputContainer">
        <label className="inputTitle">
          <p>Name (NRIC)</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          type="text"
          value={data.purchaser1.name}
          onChange={(e) => {
            setData({
              ...data,
              purchaser1: { ...data.purchaser1, name: e.target.value },
            });
            syncWithPartner();
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>NRIC / Co. No. / Passport No.</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          type="text"
          value={data.purchaser1.idNo}
          onChange={(e) => {
            setData({
              ...data,
              purchaser1: { ...data.purchaser1, idNo: e.target.value },
            });
            syncWithPartner();
          }}
        />
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>Gender</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <select
          value={data.purchaser1.gender}
          onChange={(e) => {
            setData({
              ...data,
              purchaser1: { ...data.purchaser1, gender: e.target.value },
            });
            syncWithPartner();
          }}>
          <option style={{ display: "none" }}> Pick Option </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>Race</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <select
          value={data.purchaser1.race}
          onChange={(e) => {
            setData({
              ...data,
              purchaser1: { ...data.purchaser1, race: e.target.value },
            });
            syncWithPartner();
          }}>
          <option style={{ display: "none" }}> Pick Option </option>
          <option value="Malay">Bumi - Malay</option>
          <option value="Chinese">Chinese</option>
          <option value="Indian">Indian</option>
          <option value="Bumi non-Malay">Bumi - Non-Malays</option>
        </select>
      </div>

      <div className="inputContainer">
        <label className="inputTitle">
          <p>Contact No.</p>
        </label>
        <label className="inputSeparator">
          <p>:</p>
        </label>
        <input
          placeholder="012-345 6789"
          type="text"
          value={data.purchaser1.contact}
          onChange={(e) => {
            setData({
              ...data,
              purchaser1: { ...data.purchaser1, contact: e.target.value },
            });
            syncWithPartner();
          }}
        />
      </div>

      {data.purchasersNum === 2 ? (
        <>
          <hr></hr>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Name</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser2.name}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, name: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>NRIC / Co. No. / Passport No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser2.idNo}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, idNo: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Gender</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser2.gender}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, gender: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Race</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser2.race}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, race: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="malay">Malay</option>
              <option value="chinese">Chinese</option>
              <option value="indian">Indian</option>
              <option value="others">Others</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Contact No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              placeholder="012-345 6789"
              type="text"
              value={data.purchaser2.contact}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, contact: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>
        </>
      ) : null}

      {/* Purchase 3 */}

      {data.purchasersNum === 3 ? (
        <>
          <hr></hr>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Name</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser2.name}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, name: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>NRIC / Co. No. / Passport No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser2.idNo}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, idNo: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Gender</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser2.gender}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, gender: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Race</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser2.race}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, race: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="malay">Malay</option>
              <option value="chinese">Chinese</option>
              <option value="indian">Indian</option>
              <option value="others">Others</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Contact No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              placeholder="012-345 6789"
              type="text"
              value={data.purchaser2.contact}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, contact: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>
          <hr></hr>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Name</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser3.name}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser3: { ...data.purchaser3, name: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>NRIC / Co. No. / Passport No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser3.idNo}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser3: { ...data.purchaser3, idNo: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Gender</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser3.gender}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser3: { ...data.purchaser3, gender: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Race</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser3.race}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser3: { ...data.purchaser3, race: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="malay">Bumi - Malay</option>
              <option value="chinese">Chinese</option>
              <option value="indian">Indian</option>
              <option value="non-malays">Bumi - Non-Malays</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Contact No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              placeholder="012-345 6789"
              type="text"
              value={data.purchaser3.contact}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser3: { ...data.purchaser1, contact: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>
        </>
      ) : null}
      {/* Purchase 4 */}

      {data.purchasersNum === 4 ? (
        <>
          <hr></hr>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Name</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser2.name}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, name: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>NRIC / Co. No. / Passport No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser2.idNo}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, idNo: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Gender</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser2.gender}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, gender: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Race</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser2.race}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, race: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="malay">Malay</option>
              <option value="chinese">Chinese</option>
              <option value="indian">Indian</option>
              <option value="others">Others</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Contact No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              placeholder="012-345 6789"
              type="text"
              value={data.purchaser2.contact}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser2: { ...data.purchaser2, contact: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <hr></hr>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Name</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser3.name}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser3: { ...data.purchaser3, name: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>NRIC / Co. No. / Passport No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser3.idNo}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser3: { ...data.purchaser3, idNo: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Gender</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser3.gender}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser3: { ...data.purchaser3, gender: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Race</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser3.race}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser3: { ...data.purchaser3, race: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="malay">Malay</option>
              <option value="chinese">Chinese</option>
              <option value="indian">Indian</option>
              <option value="others">Others</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Contact No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              placeholder="012-345 6789"
              type="text"
              value={data.purchaser3.contact}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser3: { ...data.purchaser3, contact: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <hr></hr>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Name</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser4.name}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser4: { ...data.purchaser4, name: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>NRIC / Co. No. / Passport No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={data.purchaser4.idNo}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser4: { ...data.purchaser4, idNo: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Gender</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser4.gender}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser4: { ...data.purchaser4, gender: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Race</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              value={data.purchaser4.race}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser4: { ...data.purchaser4, race: e.target.value },
                });
                syncWithPartner();
              }}>
              <option style={{ display: "none" }}> Pick Option </option>
              <option value="malay">Malay</option>
              <option value="chinese">Chinese</option>
              <option value="indian">Indian</option>
              <option value="others">Others</option>
            </select>
          </div>

          <div className="inputContainer">
            <label className="inputTitle">
              <p>Contact No.</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              placeholder="012-345 6789"
              type="text"
              value={data.purchaser4.contact}
              onChange={(e) => {
                setData({
                  ...data,
                  purchaser4: { ...data.purchaser1, contact: e.target.value },
                });
                syncWithPartner();
              }}
            />
          </div>
        </>
      ) : null}

      {data.purchasersNum >= 4 ? null : (
        <button
          type="button"
          onClick={() => AddPurchaser(data, setData)}
          className="submit">
          Add Purchaser
        </button>
      )}
      {data.purchasersNum !== 1 ? (
        <button
          type="button"
          onClick={() => RemovePurchaser(data, setData)}
          className="submit">
          Remove Purchaser
        </button>
      ) : null}
    </>
  );
}

const CheckBox = ({ data, setData }) => {
  if (data.consent === true) {
    setData({ ...data, consent: false });
  }

  if (data.consent === false) {
    setData({ ...data, consent: true });
  }
};

export default CheckBox;

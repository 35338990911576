import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import download from "downloadjs";
import OTPFill from "../assets/otp-fillable.pdf";
import blank from "../assets/blank-sign.jpg";
import date from "date-and-time";

const FillForm = async ({ data, sign, info }) => {
  // Get date
  const now = new Date();
  const dateNow = date.format(now, "DD/MM/YYYY");

  // Fetch blank Fillable PDF
  const formUrl = OTPFill;
  const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

  // Load a PDF with form fields
  const pdfDoc = await PDFDocument.load(formPdfBytes);

  //Fetch Sign example
  const signUrl = sign.client;
  const agentUrl = sign.agent;
  const blankUrl = blank;
  const signImageBytes = await fetch(signUrl).then((res) => res.arrayBuffer());
  const agentImageBytes = await fetch(agentUrl).then((res) =>
    res.arrayBuffer()
  );
  const blankImageBytes = await fetch(blankUrl).then((res) =>
    res.arrayBuffer()
  );

  // Embed the sign
  const signImage = await pdfDoc.embedPng(signImageBytes);
  const agentImage = await pdfDoc.embedPng(agentImageBytes);
  const blankSign = await pdfDoc.embedJpg(blankImageBytes);

  // Get the form containting all the fields
  const form = pdfDoc.getForm();

  // Field

  //Description of Property FIELD
  const parcelNoField = form.getTextField("parcelNo");
  const carparkNoField = form.getTextField("carparkNo");
  const parcelTypeField = form.getTextField("parcelType");
  const builtUpMField = form.getTextField("builtUpM");
  const builtUpFTField = form.getTextField("builtUpFT");
  const project = form.getTextField("project");

  //Description of PURCHARSER FIELD
  const purchaser1Namefield = form.getTextField("purchaser1-name");
  const purchaser1idNofield = form.getTextField("purchaser1-idNo");
  const purchaser1Genderfield = form.getTextField("purchaser1-gender");
  const purchaser1Racefield = form.getTextField("purchaser1-race");
  const purchaser1Contactfield = form.getTextField("purchaser1-contact");

  const purchaser2Namefield = form.getTextField("purchaser2-name");
  const purchaser2idNofield = form.getTextField("purchaser2-idNo");
  const purchaser2Genderfield = form.getTextField("purchaser2-gender");
  const purchaser2Racefield = form.getTextField("purchaser2-race");
  const purchaser2Contactfield = form.getTextField("purchaser2-contact");

  const purchaser3Namefield = form.getTextField("purchaser3-name");
  const purchaser3idNofield = form.getTextField("purchaser3-idNo");
  const purchaser3Genderfield = form.getTextField("purchaser3-gender");
  const purchaser3Racefield = form.getTextField("purchaser3-race");
  const purchaser3Contactfield = form.getTextField("purchaser3-contact");

  const purchaser4Namefield = form.getTextField("purchaser4-name");
  const purchaser4idNofield = form.getTextField("purchaser4-idNo");
  const purchaser4Genderfield = form.getTextField("purchaser4-gender");
  const purchaser4Racefield = form.getTextField("purchaser4-race");
  const purchaser4Contactfield = form.getTextField("purchaser4-contact");

  // Contact Person

  const contactNameField = form.getTextField("contact-name");
  const contactEmailField = form.getTextField("contact-email");
  const contactOfficeField = form.getTextField("contact-office");
  const contactMobileField = form.getTextField("contact-mobile");
  const contactMailing1Field = form.getTextField("contact-mailing1");
  const contactMailing2Field = form.getTextField("contact-mailing2");
  const contactMailing3Field = form.getTextField("contact-mailing3");
  const contactMailing4Field = form.getTextField("contact-mailing4");

  const dateField = form.getTextField("date");

  // Payment Details

  const parcelPriceField = form.getTextField("parcelPrice");
  const bumiDiscountField = form.getTextField("bumiDiscount");
  const bumiDiscountPriceField = form.getTextField("bumiDiscountPrice");
  const spaPriceField = form.getTextField("spaPrice");
  const bookingFeeField = form.getTextField("bookingFee");
  const paymentModeField = form.getTextField("paymentMode");
  const chequeNoField = form.getTextField("chequeNo");
  const cardCodeField = form.getTextField("cardCode");
  const receiptNoField = form.getTextField("receiptNo");
  const cashPurchaseField = form.getTextField("cashPurchase");
  const endFinanceField = form.getTextField("endFinance");
  const solicitorField = form.getTextField("solicitor");

  // Agent Info
  const agentNameField = form.getTextField("agent-name");
  const agentAgencyField = form.getTextField("agent-agency");
  const agentContactField = form.getTextField("agent-contact");
  const agentSalesDate = form.getTextField("agent-sales-date");

  // Embed the sign image
  const agentImageField = form.getButton("agent-sign");
  const signImageField = form.getButton("purchaser1-sign");
  const sign2ImageField = form.getButton("purchaser2-sign");
  const sign3ImageField = form.getButton("purchaser3-sign");
  const sign4ImageField = form.getButton("purchaser4-sign");
  const verifyImageSign = form.getButton("verify-sign");
  // SIGN
  const AckImageSign = form.getButton("acknowledgment-sign");

  signImageField.setImage(signImage);
  sign2ImageField.setImage(blankSign);
  sign3ImageField.setImage(blankSign);
  sign4ImageField.setImage(blankSign);
  verifyImageSign.setImage(blankSign);
  AckImageSign.setImage(blankSign);
  agentImageField.setImage(agentImage);

  // ----------- EMBED TEXT -------------- //

  // DESCRIPTION OF PROPETY FIELDS

  parcelNoField.setText(data.agent.parcelNo);
  carparkNoField.setText(data.agent.carparkNo);
  parcelTypeField.setText(data.agent.parcelType);
  builtUpMField.setText(data.agent.builtArea);
  builtUpFTField.setText(data.agent.builtAreaFT);
  project.setText(data.project);

  // PURCHARSERS FIELDS
  purchaser1Namefield.setText(data.purchaser1.name);
  purchaser1idNofield.setText(data.purchaser1.idNo);
  purchaser1Genderfield.setText(data.purchaser1.gender);
  purchaser1Racefield.setText(data.purchaser1.race);
  purchaser1Contactfield.setText(data.purchaser1.contact);
  dateField.setText(dateNow);

  purchaser2Namefield.setText(data.purchaser2.name);
  purchaser2idNofield.setText(data.purchaser2.idNo);
  purchaser2Genderfield.setText(data.purchaser2.gender);
  purchaser2Racefield.setText(data.purchaser2.race);
  purchaser2Contactfield.setText(data.purchaser2.contact);

  purchaser3Namefield.setText(data.purchaser3.name);
  purchaser3idNofield.setText(data.purchaser3.idNo);
  purchaser3Genderfield.setText(data.purchaser3.gender);
  purchaser3Racefield.setText(data.purchaser3.race);
  purchaser3Contactfield.setText(data.purchaser3.contact);

  purchaser4Namefield.setText(data.purchaser4.name);
  purchaser4idNofield.setText(data.purchaser4.idNo);
  purchaser4Genderfield.setText(data.purchaser4.gender);
  purchaser4Racefield.setText(data.purchaser4.race);
  purchaser4Contactfield.setText(data.purchaser4.contact);

  // CONTACT PERSON FIELDS
  contactNameField.setText(data.name);
  contactEmailField.setText(data.email);
  contactOfficeField.setText(data.office);
  contactMobileField.setText(data.mobile);
  contactMailing1Field.setText(data.mailing);

  // PAYMENT DETAILS

  parcelPriceField.setText(data.agent.parcelPrice);
  bumiDiscountField.setText(data.agent.bumiDiscount);
  bumiDiscountPriceField.setText(data.agent.bumiDiscountAmount);
  spaPriceField.setText(data.agent.spaPrice);
  bookingFeeField.setText(data.agent.bookingFee);
  paymentModeField.setText(data.agent.paymentMode);
  chequeNoField.setText(data.agent.chequeNo);
  cardCodeField.setText(data.agent.cardNo);
  receiptNoField.setText(data.agent.receiptNo);
  cashPurchaseField.setText(data.agent.cashPurchase);
  endFinanceField.setText(data.agent.endFinance);
  solicitorField.setText(data.agent.solicitor);

  // Agent Info

  agentNameField.setText(info.name);
  agentAgencyField.setText(info.agency);
  agentContactField.setText(info.contact);
  agentSalesDate.setText(dateNow);

  // ---------- Enable readonly ----------

  // DESCRIPTION OF PROPETY FIELDS

  parcelNoField.enableReadOnly();
  carparkNoField.enableReadOnly();
  parcelTypeField.enableReadOnly();
  builtUpMField.enableReadOnly();
  builtUpFTField.enableReadOnly();
  project.enableReadOnly();

  // PURCHARSERS FIELDS
  purchaser1Namefield.enableReadOnly();
  purchaser1idNofield.enableReadOnly();
  purchaser1Genderfield.enableReadOnly();
  purchaser1Racefield.enableReadOnly();
  purchaser1Contactfield.enableReadOnly();
  dateField.enableReadOnly();

  purchaser2Namefield.enableReadOnly();
  purchaser2idNofield.enableReadOnly();
  purchaser2Genderfield.enableReadOnly();
  purchaser2Racefield.enableReadOnly();
  purchaser2Contactfield.enableReadOnly();

  purchaser3Namefield.enableReadOnly();
  purchaser3idNofield.enableReadOnly();
  purchaser3Genderfield.enableReadOnly();
  purchaser3Racefield.enableReadOnly();
  purchaser3Contactfield.enableReadOnly();

  purchaser4Namefield.enableReadOnly();
  purchaser4idNofield.enableReadOnly();
  purchaser4Genderfield.enableReadOnly();
  purchaser4Racefield.enableReadOnly();
  purchaser4Contactfield.enableReadOnly();

  // CONTACT PERSON FIELDS
  contactNameField.enableReadOnly();
  contactEmailField.enableReadOnly();
  contactOfficeField.enableReadOnly();
  contactMobileField.enableReadOnly();
  contactMailing1Field.enableReadOnly();
  contactMailing2Field.enableReadOnly();
  contactMailing3Field.enableReadOnly();
  contactMailing4Field.enableReadOnly();

  // PAYMENT DETAILS

  parcelPriceField.enableReadOnly();
  bumiDiscountField.enableReadOnly();
  bumiDiscountPriceField.enableReadOnly();
  spaPriceField.enableReadOnly();

  // Enable this later in admin
  // bookingFeeField.enableReadOnly()
  // paymentModeField.enableReadOnly()
  // chequeNoField.enableReadOnly()
  // cardCodeField.enableReadOnly()
  // receiptNoField.enableReadOnly()
  // cashPurchaseField.enableReadOnly()
  // endFinanceField.enableReadOnly()
  // solicitorField.enableReadOnly()

  // AGENT DETAILS

  agentNameField.enableReadOnly();
  agentAgencyField.enableReadOnly();
  agentContactField.enableReadOnly();
  agentSalesDate.enableReadOnly();

  // Serialize the PDFDocument to bytes(a Uint8Array)
  const pdfBytes = await pdfDoc.save();

  download(pdfBytes, `${data.email}.pdf`, "application/pdf");
};

export default FillForm;

const CheckBoxPrivacy = ({data, setData}) => {

    if (data.privacy === true){
        setData({...data, privacy: false})
    }

    if (data.privacy === false){
        setData({...data, privacy: true})
    }

}

export default CheckBoxPrivacy